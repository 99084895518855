button, .button
  display: inline-block
  padding: $spacing / 4 $spacing
  position: relative

  border: none
  border-radius: $spacing / 8

  font-size: 0.8333333333rem
  font-weight: 400
  text-decoration: none
  line-height: $spacing

  background-color: $portage
  outline: none
  user-select: none
  transition: color 0.15s, background-color 0.15s

  &, &:active, &:visited, &:focus
    color: $white

  &:active
    background-color: $blue-marguerite
