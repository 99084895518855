form
  padding: $spacing $spacing / 2

  background-color: transparentize($black, 0.85)
  border-radius: $spacing / 4

  h2
    margin-top: 0

  .actions
    margin-bottom: $spacing / 8
