cite
  display: block
  margin: 0
  font-size: 0.75rem
  text-align: center

  img
    display: inline-block
    width: $spacing
    height: $spacing
    border: 1px solid $gull-gray
    border-radius: 50%
    vertical-align: middle
    margin-right: $spacing / 4
