.inclusions
  margin-top: $spacing * 2

  @include tablet
    padding-left: $spacing
    padding-right: $spacing

.inclusion
  margin-top: $spacing

  display: flex

  p, figure
    margin-top: 0
    margin-bottom: 0

  figure
    width: $spacing * 1.5
    height: $spacing * 1.5
    margin-top: $spacing * 0.25
    margin-right: $spacing / 2

  img
    border-radius: 50%

  p
    flex: 1
