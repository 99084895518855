html, body
  @include g-play-theme

  height: 100%
  margin: 0

  // prevent the text from being resized on rotate in ios
  -webkit-text-size-adjust: none

  font-family: "proxima-nova", sans-serif
  font-size: 18px
  font-weight: 300
  line-height: $spacing

  @include tablet
    font-size: 21px

  @include desktop
    font-size: 24px