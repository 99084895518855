main
  min-height: 100%

  // Ensure the menu starts with a white background
  border-top: $spacing * 2 solid $white

  &.single-section
    display: flex
    align-items: center
    justify-content: center
