.inputs
  display: flex
  margin: $spacing 0

  input
    margin: 0

  & > *
    flex: 1 1 0px

  & > *:not(:first-of-type)
    margin-left: $spacing / 4

  & > *:not(:last-of-type)
    margin-right: $spacing / 4
