.menu-button
  position: fixed
  top: 0
  right: 0
  z-index: 300

  width: $spacing * 2
  line-height: $spacing * 2
  padding: 0
  margin: 0

  &, &:active
    background-color: transparent

  @include tablet
    display: none

  img
    display: inline
