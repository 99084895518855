.paypal button
  padding: 0

  &, &:active, &:visited, &:focus
    font-size: 0.7rem
    text-transform: inherit
    text-decoration: underline
    font-weight: inherit
    text-align: center

    color: inherit
    background-color: transparent
