input.inline

  display: inline-block
  width: $spacing * 1.75
  padding: 0
  margin: 0 $spacing / 8

  line-height: auto

  border: 2px solid transparentize($white, 0.4)
  text-align: center

  color: $transparent-white
  background-color: transparentize($white, 0.75)
  border-radius: $spacing / 8
