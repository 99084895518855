#subscribe

  .content
    margin: $spacing 0

    @include tablet
      display: flex
      align-items: flex-start

  aside, form
    margin: 0 $spacing / 2

  aside
    flex: 3

    p
      margin-top: 0

    ul
      margin-bottom: 0

  form
    flex: 2