h1, h2, h3
  font-size: 1.75rem
  font-weight: 800
  text-align: center
  margin-top: $spacing
  margin-bottom: $spacing
  line-height: $spacing * 1.5

h1
  margin-bottom: $spacing * 0.6

  & + .main-subhead, & + .subhead
    margin-top: $spacing * 0.6

h2
  font-size: 1.3333333333rem
  line-height: $spacing
  margin-bottom: $spacing * 0.75

  & + .context
    margin-top: $spacing * 0.75

h3
  font-weight: 700
  text-align: left
  font-size: 1rem
  line-height: $spacing